<template>
    <div>
        <base-header class="pb-6">
            <div class="row align-items-center py-4">
                <div class="col-lg-6 col-7">
                    <h6 class="h2 text-white d-inline-block mb-0"></h6>
                </div>      
            </div>
        </base-header>
        <div class="container-fluid mt--6">
            <div class="card mb-4 mt--7">
                <div class="card-header">
                    <div class="row">
                        <div class="col-3">
                            <h3 class="mb-0">{{ tt('app_auditing') }}</h3>
                        </div>
                        <div class="col-2 text-right">
                            <input type="button" class="btn btn-sm btn-outline-dark" value="Export as Excel" id="export-kpi-report">
                        </div>
                        <div class="col-3">
                            <base-input input-classes="form-control-sm" v-model="checkDuplicate.auditable_type" :placeholder="tt('auditable_type')"/>
                        </div>
                        <div class="col-3">
                            <base-input type="date" input-classes="form-control-sm" v-model="checkDuplicate.date" :placeholder="tt('date')"/>
                        </div>
                        <div class="col-1 text-right">
                            <i class="fa fa-filter btn btn-sm btn-default"></i>
                        </div>
                    </div>

                    <div class="text-center">
                        <img src="http://04d4aac6f71c.ngrok.io/images/other/data_is_empty.gif" alt="Data Is Empty" style="width: 450px">
                        <p class="text-muted">{{ tt('no_activity_in_smartcat_today') }}</p>
                    </div>
                </div>
                <div class="card-footer">
                    <span class="text-muted float-right">
                        <small>{{ tt('this_activities_log_has_been_taken_today') }}</small>
                    </span>
                </div>
            </div>
        </div>
        <modal :show.sync="form.show">
            <template slot="header">
                <h5 class="modal-title">{{form.title}}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('code') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('code')" :placeholder="tt('code')" v-model="checkDuplicate.code" rules="required"></base-input>

                <label class="form-control-label">{{ tt('table') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('table')" rules="required">
                    <el-select class="select-danger" v-model="checkDuplicate.table" placeholder="Choose Table">
                        <el-option class="select-danger" value="01" label="abbrevation" key="abbrevation"></el-option>
                        <el-option class="select-danger" value="01" label="account_assignment_group" key="account_assignment_group"></el-option>
                    </el-select>
                </base-input>

                <label class="form-control-label">{{ tt('column') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('column')" rules="required">
                    <el-select class="select-danger" v-model="checkDuplicate.column" placeholder="Choose Column">
                        <el-option class="select-danger" value="01" label="abbrevation" key="abbrevation"></el-option>
                        <el-option class="select-danger" value="01" label="account_assignment_group" key="account_assignment_group"></el-option>
                    </el-select>
                </base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="form.show = false">{{ tt('reset') }}</base-button>
                <base-button type="primary">
                    <span v-if="form.add">{{ tt('add') }}</span>
                    <span v-else>{{ tt('edit') }}</span>
                </base-button>
            </template>
        </modal>
    </div>
</template>
<script>
    export default {        
        data() {
            return {                
                form: {
                    add: true,
                    title: this.tt('add_table_column'),
                    show: false
                }, 
                table: {
                    data: [
                        {
                            id: 1,
                            material_number: 100007,
                            item_name: 'BEARING,BALL,ANNULAR',
                            short_description: 'BEARING,BALL:AC;2R',
                            material_type: 1014,
                            material_group: 140101,
                            uom: 'EA',
                            cat_status: 'QA',
                        },
                        {
                            id: 2,
                            material_number: 100007,
                            item_name: 'BEARING,BALL,ANNULAR',
                            short_description: 'BEARING,BALL:AC;2R',
                            material_type: 1014,
                            material_group: 140101,
                            uom: 'EA',
                            cat_status: 'CAT',
                        },
                        {
                            id: 3,
                            material_number: 100007,
                            item_name: 'BEARING,BALL,ANNULAR',
                            short_description: 'BEARING,BALL:AC;2R',
                            material_type: 1014,
                            material_group: 140101,
                            uom: 'EA',
                            cat_status: 'QA',
                        },
                        {
                            id: 3,
                            material_number: 100007,
                            item_name: 'BEARING,BALL,ANNULAR',
                            short_description: 'BEARING,BALL:AC;2R',
                            material_type: 1014,
                            material_group: 140101,
                            uom: 'EA',
                            cat_status: 'CAT',
                        },
                    ]
                },        
                checkDuplicate: {
                    
                }  
            }
        },
        methods: {
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.edit(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            create() {
                this.form.add = true;
                this.form.title = this.tt('add_table_column');
                this.form.show = true;
            },
            edit(id) {
                this.form.add = false;
                this.form.title = this.tt('edit_table_column');
                this.form.show = true;
            },
            remove(id) {
                this.confirmDialog("Are you sure to delete this data?").then((result) => {
                    if (result.value) {
                        this.alertDialog('success', 'Successed delete data');
                    } else {
                        this.alertDialog('error', 'Failed delete data');
                    }
                })
            },
        }   
    };
</script>
<style></style>
